// src/components/HomePage.js
import React from 'react';

const HomePage = () => {
  return (
    <div className="Home">
       <br />
	  <br />
      
      <img src="Home_Einladungskarte3.jpg" className="Einladungsbild fadeIn"/>

	  <br />
      <div className="BigWeddingDateDiv fadeInLong" >
         <h1 className="BigWeddingDate"> 20.04.2024 </h1>
      </div>
	 
      <img src="background_3.jpg" className="Einladungsbild fadeIn" />
      
    </div>
  );
};


export default HomePage;
