// src/components/ZusagePage.js
import React, { useState } from 'react';
import firebase from './firebase';

const ZusagePage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [allergien, setAllergien] = useState('');
    const [hauptspeise, setHauptspeise] = useState();
    const heute = new Date();
    const database = firebase.database();
    
    const Push = () => {
        database.ref(name).set({
            name: name,
            email: email,
            hauptspeise: hauptspeise,
            allergien: allergien,
            letzteAenderung: heute.toDateString()
        }).catch(alert);
        alert('Speichern erfolgreich für '+name+'! \n\n Bei Änderungen einfach ändern und nochmal Speichern :) ');
    }
    
    return (
    <div className="Programm">
        
     
        
        <div className="WichtigeInfo">
         <h1>Nimmst du die Einladung an?</h1>
      <h2 >Ja, ich will!<br /> <br /> </h2>
       
      <center>
                <input className="inputText" placeholder="Vorname Nachname" value={name}
                    onChange={(e) => setName(e.target.value)} />
                <br /><br />

                        
                <h2> Hauptspeise </h2>
                <div >
                    <input type="radio" value="Huhn" name="hauptspeise" onChange={(e) => setHauptspeise(e.target.value)}/> 
                        Gefüllte Bio Hühnerbrust, Rohschinkenknusper, Wildkräuterrisotto
                    <br />
                    <input type="radio" value="Medaillons" name="hauptspeise" onChange={(e) => setHauptspeise(e.target.value)}/> 
                        Rosa Schweinemedaillons, Parmesankruste, Erdäpfel-Karottengröstl
                    <br />
                    <input type="radio" value="Zucchini" name="hauptspeise" onChange={(e) => setHauptspeise(e.target.value)}/> 
                        Gefüllte runde Zucchini, Paradeiser Emmer, Petersilienpesto 
                    <br />
                </div>
                <br />
                            
                <h2> Für unsere kleinen Gäste </h2>
                <div >
                    <input type="radio" value="Schnitzel" name="hauptspeise" onChange={(e) => setHauptspeise(e.target.value)}/> 
                        Schnitzel mit Pommes
                    <br />
                   
                </div>
                <br /><br />
                        
                <h2> Hast du eine Allergie oder eine Unverträglichkeit? </h2>
                <input className="inputText" placeholder="Allergien & Wissenswertes" value={allergien}
                    onChange={(e) => setAllergien(e.target.value)} />
                <br /><br />
                    
                    
                <h2> E-Mail Adresse</h2> 
                Du möchtest einen Link für die Fotos und Videos nach der Hochzeit erhalten? Hier kannst du deine Mail-Adresse angeben <br />
                <input className="inputText" type="e-Mail" placeholder="E-Mail Adresse" value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                <br /><br />
                
                <button onClick={Push}>Senden</button>
                
                <br /><br />
            </center>
        
    </div>
    </div>
  );
};

export default ZusagePage;
