// src/components/LocationPage.js
import React, {useEffect, useState} from "react";

const images = [
  '1.jpg',
  '2.jpg',
  '3.jpg'
  // Add more image URLs here
];



const LocationPage = () => {
	
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
    
  const goToBahnhof = () => {
    setCurrentIndex(2);
    window.scrollTo(0, document.body.scrollHeight);
  };
    
  const goToParken = () => {
    setCurrentIndex(1);
    window.scrollTo(0, document.body.scrollHeight);
  };
    
  const goToAtrium = () => {
    setCurrentIndex(0);
    window.scrollTo(0, document.body.scrollHeight);
  };


	
	
  return (
    <div className="Location">
      
	  <br /> <br />
      <div className="WichtigeInfo">
	  <p className="Schrift">
	  
	  <h1>Das "Atrium"</h1>
	  
	  Wir feiern unsere Hochzeit im wunderschönen Atrium <br />
	  inmitten der Weinberge in Krems, Niederösterreich
	  <p> <a href = "https://maps.app.goo.gl/V6D7CLua2cJvJuUW9" > Google Maps </a></p>
      
      Vitikultur MOSER <br />
      Untere Wiener Straße 1 <br />
      3495 Rohrendorf bei Krems
      <br /> <br /> 
	  <img src="logo.png" width = "40px"/>
	  <br/> 
	Die Trauung findet Vorort im Atrium statt <br /> und  <br />
	bei Schönwetter im Garten<br /> 
	
      
      <br /> 
	  <img src="logo.png" width = "40px"/>
	  <br/> 
      Parkplätze gibt es ausreichend und die Autos dürfen über Nacht stehen bleiben.<br/>
      Der Bahnhof <b>Rohrendorf bei Krems</b> ist zu Fuß erreichbar! 
	
	
	 </p>
	  
	  
	  </div>
	  
	  <br />
	  <br />
	  <br />
      
      <table className="buttonTable">
      <tr> 
        <td> <input type ="button" value = "Atrium" onClick = {goToAtrium} /> </td> 
        <td> <input type ="button" value = "Parkplätze" onClick = {goToParken} /> </td> 
        <td> <input type ="button" value = "Bahnhof" onClick = {goToBahnhof} /> </td> 
      
      </tr>
      </table>
      
	  <br />
	  
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="LocationPics" onClick={goToNext} />
       
	  <br />
	  <br />
	  <br />
	  <br />
	  
	  
    </div>
  );
};

export default LocationPage;
