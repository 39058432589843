// src/components/AccommodationPage.js
import React from 'react';

const AccommodationPage = () => {
  return (
    <div className="Accomodation">
      <h1 className="Ueberschrift">Unterkünfte</h1>
      <h2 className="Ueberschrift2"> Empfehlungen und Kontakte </h2>
      
      <p className="SchriftAcco">
      <ul>
          <li>Wir übernehmen die Organisation der Cottages! Bitte an das Brautpaar wenden. Kosten sind ca. 70 Euro pro        Person, wenn wir eine Cottage (8 Schlafplätze) vollbekommen  </li>
          <li>Am Sonntag sind wir im Loisium anzutreffen! Hintergrund: Wer am nächsten Tag voraussichtlich noch nicht heimfahren kann / will, kann mit uns noch gemeinsam irgendwo essen oder Nachmittags zum Spa gehen </li>
      </ul>  
    </p>
    
      <br />
      <table className="accoTable">
       <tr> 
        <td>
          <b>Cottages Grafenegg</b> <br />
          Schloßstraße, 
          3485 Grafenegg<br />
          02735 5500<br />
          <a href="https://www.grafenegg.com/de/cottages">https://www.grafenegg.com/de/cottages</a><br />
        </td>
        <td > Pro Cottage sind 4 Doppelbettzimmer mit jeweils eigenem Badezimmer! <br /> 70€ pro Person <br /> Frühstück +15€ pro Person <br /> Late-Checkout +30€ pro Person </td>
        <td> <img src="acco0.jpg" /></td>
      </tr>
      <tr> 
        <td>
          <b>Loisium Hotel Wine Spa Resort Loisium</b> <br />
          Allee 2 3550 Langenlois<br />
          02734 77100-200<br />
          <a href="https://www.loisium.com/langenlois/zimmer-suiten">www.loisium.com/langenlois/zimmer-suiten</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco1.jpg" /></td>
      </tr>
      <tr> 
        <td>
          <b>Landhaus Weinblick</b> <br />
          Hans Heppenheimer Straße 4<br />
          3495 Rohrendorf<br />
          0660 111 00 429<br />
          <a href="https://www.landhaus-weinblick.at">www.landhaus-weinblick.at</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco2.jpg" /></td>
      </tr>
      <tr> 
        <td>
          <b>Steigenberger Avance Hotel Spa</b> <br />
          Am Goldberg 2, 3500 Krems<br />
          02732 71010<br />
          <a href="https://www.steigenberger.com/krems">www.steigenberger.com/krems</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco3.jpg" /></td>
      </tr>
            <tr> 
        <td>
          <b>Gästehaus Fischer</b> <br />
          Obere Hauptstraße 58<br />
          3495 Rohrendorf <br />
          02732 77957<br />
          <a href="https://www.gaestehausfischer.at">www.gaestehausfischer.at</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco4.jpg" /></td>
      </tr>
      <tr> 
        <td>
          <b>Hotel Klinglhuber</b> <br />
          Wiener Straße 10<br />
          3500 Krems <br />
          02732 86960<br />
          <a href="https://www.klinglhuber.com">www.klinglhuber.com</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco5.jpg" /></td>
      </tr>
      <tr> 
        <td>
          <b>Gästehaus Rosenberger</b> <br />
          Leisergasse 29<br />
          3495 Rohrendorf <br/>
          02732 83843<br />
          <a href="https://www.rosenbergerwein.at">www.rosenbergerwein.at</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco6.jpg" /></td>
      </tr>
      <tr> 
        <td>
          <b>Gästehaus Kitzler</b> <br />
          Untere Hauptstraße 53<br />
          3495 Rohrendorf <br/>
          02732 79686<br />
          <a href="https://www.loess.at">www.loess.at</a><br />
        </td> 
        <td > </td>
        <td> <img src="acco7.jpg" /></td>
      </tr>
        <tr> 
        <td>
          <b>Orange Wings Hotel Krems</b> <br />
          Hofrat-Erben-Straße 4, 3500 Krems an der Donau<br />
          3500 Krems an der Donau <br />
          02732 78010<br />
          <a href="https://www.orangewings.com/">https://www.orangewings.com/</a><br />
        </td>
        <td > </td>
        <td> <img src="acco8.jpg" /></td>
      </tr>
      </table>
      
    </div>
  );
};

export default AccommodationPage;
