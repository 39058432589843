// src/App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from './HomePage';
import LocationPage from './LocationPage';
import HochzeitPage from './HochzeitPage';
import AccommodationPage from './AccomodationPage';
import TimelinePage from './TimelinePage';
import ContactsPage from './ContactsPage';
import ZusagePage from './ZusagePage';



function App() {
    
    useEffect(() => {
       document.title = 'Juliandi';
    }, []);
    
    const [hideNav, setHideNav] = useState("hamburgNavOpen");
    const [burgerMenuChecked, setBurgerMenuChecked] = useState();
    

    
    const setClassOnNav = (event) => {
       if(burgerMenuChecked === undefined)  {
           setBurgerMenuChecked(false);
       }
        
      if (event.target.checked) {
          setBurgerMenuChecked(true);
      } else {
          setBurgerMenuChecked(false);
      }
    }
    
	return (
	< >
        
		<nav>
			<div class="HamburgerSymbol">
                <input type="checkbox" id="hamburg" checked={burgerMenuChecked} onChange={setClassOnNav} />
                <label for="hamburg" class="hamburg">
                    <span class="line"></span>
                    <span class="line"></span>
                    <span class="line"></span>
                </label>
            </div>
            <div className={burgerMenuChecked ? "hamburgNavOpen" : "hamburgNavClosed"}>
                <a href="/">Wir heiraten</a>
                <a href="/Location">Location</a>
                <a href="/Hochzeit">Details</a>
                <a href="/Zeitplan">Zeitplan</a>
                <a href="/Zusage">Zusage & Essen</a>
                <a href="/Programmbeitrag">Programm</a>
				<a href="/Unterkunfte">Unterkünfte</a>

            </div>
        
			<div className="nav-links">
				<a href="/">Wir heiraten</a>
                <a href="/Location">Location</a>
                <a href="/Hochzeit">Details</a>
                <a href="/Zeitplan">Zeitplan</a>
                <a href="/Zusage">Zusage & Essen</a>
                <a href="/Programmbeitrag">Programm</a>
                <a href="/Unterkunfte">Unterkünfte</a>
			</div>
		</nav>
		
		<BrowserRouter>
			<Routes>
			    <Route path="/" exact element = {<HomePage /> } /> 
                <Route path="/Location" element={<LocationPage />} />
                <Route path="/Hochzeit" element={<HochzeitPage />} />
                <Route path="/Zusage" element={<ZusagePage />} />

				<Route path="/Unterkunfte" element={<AccommodationPage />} />
				<Route path="/Zeitplan" element={<TimelinePage />} />
				<Route path="/Programmbeitrag" element={<ContactsPage />} />
			</Routes>
		</BrowserRouter>
	</ >
	);
}

export default App;
