import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyARIRrpU_nJkb03p181pL3y4kEbFQ-aeMk",
  authDomain: "hochzeit-julia-und-andi.firebaseapp.com",
  databaseURL: "https://hochzeit-julia-und-andi-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "hochzeit-julia-und-andi",
  storageBucket: "hochzeit-julia-und-andi.appspot.com",
  messagingSenderId: "1066527201196",
  appId: "1:1066527201196:web:861296299aa4d0d6378c09",
  measurementId: "G-1W7PK7281D"
};

firebase.initializeApp(firebaseConfig);
 
export default firebase;