// src/components/ContactsPage.js
import React from 'react';

const ContactsPage = () => {
 
    return (
    <div className="Programm">
        
     
        
        <div className="WichtigeInfo">
         <h1>Programm</h1>
      <p className="Schrift">Du möchtest uns überraschen? <br /> <br />
        
        <img src="cool2.jpg" width="80%" max-width="600px" /> <br /> <br />
        
       Vielleicht mit einer Rede? Einem Gedicht? Einem Song? Einem Spiel? Einem Video? <br /><br />
        
      Was auch immer es sein soll, wir freuen uns darauf und haben ein wenig Platz im Zeitplan reserviert. <br /> <br />
      
      Wir haben Andis Schwester als Koordinatorin und die beiden Trauzeugen beauftragt, darauf zu achten, <br /> dass alles im zeitlichen Rahmen bleibt
        und die Fotografen und der DJ Bescheid wissen <br />(Hauptsache wir nicht) <br />
        <br />
        
    </p>
    </div>
    <h1>Dein Beitrag wird dankend         berücksichtigt von der Ansprechperson deines Vertrauens </h1>
    <br /> <br />
        <table className="contactTable">
       <tr>
        <td width="33%"><b>Claudia Bugl</b></td>
        <td width="33%"><b>Frank Peter</b></td>
        <td width="30%"><b>Daniel Gutmann</b></td>
       </tr>
       <tr> 
        <td width="33%"><img src="Claudia.jpg" width="100%" /></td>
        <td width="33%"><img src="frank.jpg" width="100%" /></td>
        <td width="30%"><img src="Daniel.jpg" width="100%" /></td>
      </tr> 
      </table>
     
    </div>
  );
};

export default ContactsPage;
