// src/components/HomePage.js
import React from 'react';

const HochzeitPage = () => {
  return (
    <div className="Hochzeit">
	  <br />
      
      <h1 className = "UeberschriftHochzeit">Dress Code - Cocktail</h1>
      <br />
      <div className="WichtigeInfo" >
      
      <br />
       <p className ="Schrift" >
      <b> Auszug aus der Karrierebibel zu Dresscodes </b><br /><br />
     
      Grundsätzlich sollte es zwar elegant sein, es gibt dabei aber mehr Freiräume als bei anderen Dresscodes. <br /> <br /> 
       <b>Damen</b> greifen zum bunten Cocktail-Kleid oder zum klassischen „kleinen Schwarzen“ bis hin zum schicken Hosenanzug. Schultern, Dekolleté als auch Bein müssen nicht komplett verdeckt sein. <br /> <br />
      <b>Herren</b> tragen in der Regel einen schicken Anzug, Hemd, Krawatte und lässige Schnürschuhe. Bei den Farben darf man etwas variieren. <br /> <br />
     
      
  </p>
     <img src="logo.png" width = "40px"/><br /><br />
    
      <p className="SchriftGroesser">
      <b>Julia & Andi</b><br /><br /> Wir freuen uns über eine festliche Stimmung - Der Hochzeitsort ist pompös, elegant. Gleichzeitig sind wir ein bunter Haufen und wir wollen euch so sehen, wie ihr seid. Tanzen und Feiern hat Priorität. Dresscode "Cocktail" ist als Leitfaden und Entscheidungshilfe zu sehen - Nicht als Verpflichtung.
    <br /><br />
      </p>
    </div>
    
    
    
	 <br /> <br />
      <img src="lieb1.jpg" className="Einladungsbild fadeIn" />
    
      
    <h1 className = "UeberschriftHochzeit">Fotographie und Video</h1>  
      <div className="WichtigeInfo" >
      <p className ="Schrift" >
          Engagiert ist das tolle Fotographen-Paar Marion und Andreas Kastenhofer von <a href="https://cuore-ama.at/">Cuore Ama</a>. <br /><br />
      <img src="logo.png" width = "40px"/><br /><br />
          Die Fotos und Videos gibt es im Anschluss. Die Handys dürfen in den Taschen bleiben :) 

      </p>

      </div>
        <br /><br /><br /><br /><br />
      
    </div>
      
      
    
  );
};


export default HochzeitPage;
