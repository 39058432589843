// src/components/TimelinePage.js
import React from 'react';

const TimelinePage = () => {
   return (
    <div className="Zeitplan">
        
     
        
        <div className="WichtigeInfo">
         <h1>Zeitplan</h1>
        
        <img src="Zeitplan.JPG" width="80%" max-width="800px" /> <br /> <br />
       </div>
        
      
    </div>
  );
};

export default TimelinePage;
